import React, { useCallback, useEffect, useState } from "react";
import "./SideBar.css";
import logo from "../../Assets/Icons/logo.png";
import { NavLink, useLocation } from "react-router-dom";
import { Button } from "@mui/material";
import MenuIcon from "../../Assets/Icons/menu.png";
import CrossIcon from "../../Assets/Icons/cross.png";
import secureLocalStorage from "react-secure-storage";

export const routes = [
  {
    path: "/",
    title: "Dashboard",
    icon: require("../../Assets/Icons/No-color-Icon/activity.png"),
    icon_color: require("../../Assets/Icons/Color-Icon/activity.png"),
  },
  {
    path: "/meetings",
    title: "Meetings",
    icon: require("../../Assets/Icons/No-color-Icon/profile-2user.png"),
    icon_color: require("../../Assets/Icons/Color-Icon/profile-2user.png"),
  },
  {
    path: "/meeting-points",
    title: "Meeting Points",
    icon: require("../../Assets/Icons/No-color-Icon/edit.png"),
    icon_color: require("../../Assets/Icons/Color-Icon/edit.png"),
  },
  {
    path: "/upcoming-meetings",
    title: "Upcoming Meetings",
    icon: require("../../Assets/Icons/No-color-Icon/people.png"),
    icon_color: require("../../Assets/Icons/Color-Icon/people.png"),
  },
  {
    path: "/complete-meetings",
    title: "Complete Meetings",
    icon: require("../../Assets/Icons/No-color-Icon/video-circle.png"),
    icon_color: require("../../Assets/Icons/Color-Icon/video-circle.png"),
  },
  {
    path: "/followups",
    title: "Followups",
    icon: require("../../Assets/Icons/No-color-Icon/user-tick.png"),
    icon_color: require("../../Assets/Icons/Color-Icon/user-tick.png"),
  },
  {
    path: "/recurring-setup",
    title: "Recurring Followups",
    icon: require("../../Assets/Icons/No-color-Icon/task-square.png"),
    icon_color: require("../../Assets/Icons/Color-Icon/task-square.png"),
  },
  {
    path: "/recurring-followups-call-summary",
    title: "Follow-Ups Call Summary",
    icon: require("../../Assets/Icons/Color-Icon/document-text.png"),
    icon_color: require("../../Assets/Icons/No-color-Icon/document-text.png"),
  },
  {
    path: "/strike-rewards",
    title: "Strike / Rewards",
    icon: require("../../Assets/Icons/No-color-Icon/discount-shape.png"),
    icon_color: require("../../Assets/Icons/Color-Icon/discount-shape.png"),
  },
  {
    path: "/incoming-queries",
    title: "Incoming Queries",
    icon: require("../../Assets/Icons/No-color-Icon/clipboard-text.png"),
    icon_color: require("../../Assets/Icons/Color-Icon/clipboard-text.png"),
  },
  {
    path: "/settings",
    title: "Settings",
    icon: require("../../Assets/Icons/No-color-Icon/setting-2.png"),
    icon_color: require("../../Assets/Icons/Color-Icon/setting-2.png"),
  },
];

const SideBar = () => {
  const privileges = secureLocalStorage.getItem("privileges");
  // const privileges = false;

  const filteredRoutes = routes.filter((route) => {
    // If condition 'a' is false, filter out certain objects
    if (!privileges) {
      // Define the conditions for filtering out objects
      return !(
        route.path === "/" ||
        route.path === "/strike-rewards" ||
        route.path === "/settings"
      );
    }
    // If condition 'a' is true, include all objects
    return true;
  });

  console.log("ROUTES: ", filteredRoutes);

  console.log("Privileges: ", privileges);
  const location = useLocation();
  let location_path = location.pathname;
  const [isSideBarVisible, setIsSideBarVisible] = useState(true);
  const toggleNavbar = () => {
    setIsSideBarVisible(!isSideBarVisible);
  };

  // useEffect(() => {
  //   if (window.innerWidth <= 1280) {
  //     setIsSideBarVisible(false);
  //   } else {
  //     setIsSideBarVisible(true);
  //   }
  // }, []);

  const resizeHandler = () => {
    setIsSideBarVisible(window.innerWidth >= 1280);
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  const [activeLink, setActiveLink] = useState(null);

  const handleMouseEnter = useCallback((index) => {
    setActiveLink(index);
  }, []);

  const handleMouseLeave = () => {
    setActiveLink(null);
  };

  return (
    <>
      {isSideBarVisible ? (
        // <FaRegTimesCircle className="menu-icon" onClick={toggleNavbar} />
        <Button className="menu-icon" onClick={toggleNavbar}>
          <img src={CrossIcon} alt="" />
        </Button>
      ) : (
        <Button className="menu-icon" onClick={toggleNavbar}>
          <img src={MenuIcon} alt="" />
        </Button>
        // <FaAlignLeft className="menu-icon" onClick={toggleNavbar} />
      )}
      <div
        className={`sidebar-menu ${isSideBarVisible ? "visible" : "hidden"}`}
      >
        <div className="logo">
          <img src={logo} alt="picklist-logo" />
        </div>
        <div className="routes">
          {filteredRoutes &&
            filteredRoutes.map((r, index) => {
              return (
                <div key={r.title}>
                  <div className="menu-wrapper">
                    <NavLink
                      activeclassname="active"
                      className="menu-item"
                      to={r.path}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <img
                        src={
                          activeLink === index || location_path === r.path
                            ? r.icon_color
                            : r.icon
                        }
                        alt=""
                      />
                      <p>{r.title}</p>
                    </NavLink>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default SideBar;
