import React from "react";
// import NinjaLoader from "../../Assets/Images/Loader.gif";
import "./Loader.css";
import { Bars } from "react-loader-spinner";

const Loader = () => {
  return (
    <div className="ninja-loader">
      {/* <img src={NinjaLoader} alt="" /> */}
      <Bars
        height="80"
        width="80"
        color="#1976d2"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};

export default Loader;
