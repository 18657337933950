import React from "react";
import { Navigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const RoleRoute = ({ element }) => {
  let isAllowed = JSON.parse(secureLocalStorage.getItem("privileges"));
  console.log("ISALLOWED: ", isAllowed);
  return isAllowed ? element : <Navigate to="/unauthorized" />;
};

export default RoleRoute;
