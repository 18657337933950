import { CLEAR_ERRORS } from "../Constants/LoginConstants";
import {
  ACTIONABLE_DISCUSSION_POINTS_BAR_CHART__FAILURE,
  ACTIONABLE_DISCUSSION_POINTS_BAR_CHART__REQUEST,
  ACTIONABLE_DISCUSSION_POINTS_BAR_CHART__SUCCESS,
  ADD_CREATE_FOLLOWUP_MEETING__FAILURE,
  ADD_CREATE_FOLLOWUP_MEETING__REQUEST,
  ADD_CREATE_FOLLOWUP_MEETING__SUCCESS,
  ADD_FOLLOWUP_CALL__FAILURE,
  ADD_FOLLOWUP_CALL__REQUEST,
  ADD_FOLLOWUP_CALL__SUCCESS,
  ADD_FOLLOWUP_REMARK__FAILURE,
  ADD_FOLLOWUP_REMARK__REQUEST,
  ADD_FOLLOWUP_REMARK__SUCCESS,
  ADD_MEETING_FAILURE,
  ADD_MEETING_REQUEST,
  ADD_MEETING_SUCCESS,
  ADD_MEET_SETTING_FAILURE,
  ADD_MEET_SETTING_REQUEST,
  ADD_MEET_SETTING_SUCCESS,
  CANCEL_MEETING__FAILURE,
  CANCEL_MEETING__REQUEST,
  CANCEL_MEETING__SUCCESS,
  CHANGE_MEETING_STATUS_FAILURE,
  CHANGE_MEETING_STATUS_REQUEST,
  CHANGE_MEETING_STATUS_SUCCESS,
  CONCLUDE_FOLLOWUP__FAILURE,
  CONCLUDE_FOLLOWUP__REQUEST,
  CONCLUDE_FOLLOWUP__SUCCESS,
  CONFERENCE_CALL__FAILURE,
  CONFERENCE_CALL__REQUEST,
  CONFERENCE_CALL__SUCCESS,
  CREATE_DISCUSSION_FAILURE,
  CREATE_DISCUSSION_REQUEST,
  CREATE_DISCUSSION_SUCCESS,
  CREATE_QUERIES_FAILURE,
  CREATE_QUERIES_REQUEST,
  CREATE_QUERIES_SUCCESS,
  CREATE_RECURRING_FOLLOWUP_FAILURE,
  CREATE_RECURRING_FOLLOWUP_REQUEST,
  CREATE_RECURRING_FOLLOWUP_SUCCESS,
  CREATE_RESPONSE_FAILURE,
  CREATE_RESPONSE_REQUEST,
  CREATE_RESPONSE_SUCCESS,
  CREATE_TASK_FAILURE,
  CREATE_TASK_REQUEST,
  CREATE_TASK_SUCCESS,
  DELETE_RECURRING_SETUP_DATA__FAILURE,
  DELETE_RECURRING_SETUP_DATA__REQUEST,
  DELETE_RECURRING_SETUP_DATA__SUCCESS,
  DISCUSSION_POINT_TYPES_BAR_CHART__FAILURE,
  DISCUSSION_POINT_TYPES_BAR_CHART__REQUEST,
  DISCUSSION_POINT_TYPES_BAR_CHART__SUCCESS,
  EA_DESIGNATION_USER__FAILURE,
  EA_DESIGNATION_USER__REQUEST,
  EA_DESIGNATION_USER__SUCCESS,
  EDIT_UPDATE_MEETING__FAILURE,
  EDIT_UPDATE_MEETING__REQUEST,
  EDIT_UPDATE_MEETING__SUCCESS,
  END_CALL__FAILURE,
  END_CALL__REQUEST,
  END_CALL__SUCCESS,
  FOLLOWUP_CALL_BAR_CHART__FAILURE,
  FOLLOWUP_CALL_BAR_CHART__REQUEST,
  FOLLOWUP_CALL_BAR_CHART__SUCCESS,
  FOLLOWUP_CALL_DETAILS_LIST_DATA__FAILURE,
  FOLLOWUP_CALL_DETAILS_LIST_DATA__REQUEST,
  FOLLOWUP_CALL_DETAILS_LIST_DATA__SUCCESS,
  FOLLOWUP_CALL_DETAILS__FAILURE,
  FOLLOWUP_CALL_DETAILS__REQUEST,
  FOLLOWUP_CALL_DETAILS__SUCCESS,
  GET_ATTENDEE_LIST__FAILURE,
  GET_ATTENDEE_LIST__REQUEST,
  GET_ATTENDEE_LIST__SUCCESS,
  GET_CALL_CREATE_DATA__FAILURE,
  GET_CALL_CREATE_DATA__REQUEST,
  GET_CALL_CREATE_DATA__SUCCESS,
  GET_COMPANY_DURATION_PIE_CHART__FAILURE,
  GET_COMPANY_DURATION_PIE_CHART__REQUEST,
  GET_COMPANY_DURATION_PIE_CHART__SUCCESS,
  GET_COMPANY_LIST_FAILURE,
  GET_COMPANY_LIST_REQUEST,
  GET_COMPANY_LIST_SUCCESS,
  GET_COMPANY_PIE_CHART__FAILURE,
  GET_COMPANY_PIE_CHART__REQUEST,
  GET_COMPANY_PIE_CHART__SUCCESS,
  GET_COMPLETED_MEETING_FAILURE,
  GET_COMPLETED_MEETING_REQUEST,
  GET_COMPLETED_MEETING_SUCCESS,
  GET_CREATE_FOLLOWUPS_DROPDOWN__FAILURE,
  GET_CREATE_FOLLOWUPS_DROPDOWN__REQUEST,
  GET_CREATE_FOLLOWUPS_DROPDOWN__SUCCESS,
  GET_DASHBOARD_METRICS__FAILURE,
  GET_DASHBOARD_METRICS__REQUEST,
  GET_DASHBOARD_METRICS__SUCCESS,
  GET_DEPARTMENT_LIST_FAILURE,
  GET_DEPARTMENT_LIST_REQUEST,
  GET_DEPARTMENT_LIST_SUCCESS,
  GET_DISCUSSION_DETAIL__FAILURE,
  GET_DISCUSSION_DETAIL__REQUEST,
  GET_DISCUSSION_DETAIL__SUCCESS,
  GET_EA_NAME_LIST_FAILURE,
  GET_EA_NAME_LIST_REQUEST,
  GET_EA_NAME_LIST_SUCCESS,
  GET_FOLLOWUPS_CALL_SUMMARY__FAILURE,
  GET_FOLLOWUPS_CALL_SUMMARY__REQUEST,
  GET_FOLLOWUPS_CALL_SUMMARY__SUCCESS,
  GET_FOLLOWUPS_DROPDOWN_LIST_FAILURE,
  GET_FOLLOWUPS_DROPDOWN_LIST_REQUEST,
  GET_FOLLOWUPS_DROPDOWN_LIST_SUCCESS,
  GET_FOLLOWUPS_LIST_FAILURE,
  GET_FOLLOWUPS_LIST_REQUEST,
  GET_FOLLOWUPS_LIST_SUCCESS,
  GET_FOLLOWUP_CALL_IDS__FAILURE,
  GET_FOLLOWUP_CALL_IDS__REQUEST,
  GET_FOLLOWUP_CALL_IDS__SUCCESS,
  GET_FOLLOWUP_CALL_ID__FAILURE,
  GET_FOLLOWUP_CALL_ID__REQUEST,
  GET_FOLLOWUP_CALL_ID__SUCCESS,
  GET_MEETING_DETAILS_FAILURE,
  GET_MEETING_DETAILS_REQUEST,
  GET_MEETING_DETAILS_SUCCESS,
  GET_MEETING_DISCCUSSION_DROPDOWN_DATA_FAILURE,
  GET_MEETING_DISCCUSSION_DROPDOWN_DATA_REQUEST,
  GET_MEETING_DISCCUSSION_DROPDOWN_DATA_SUCCESS,
  GET_MEETING_DISCUSSION_POINT_LIST_FAILURE,
  GET_MEETING_DISCUSSION_POINT_LIST_REQUEST,
  GET_MEETING_DISCUSSION_POINT_LIST_SUCCESS,
  GET_MEETING_FAILURE,
  GET_MEETING_QUERY_RESPONSE_FAILURE,
  GET_MEETING_QUERY_RESPONSE_REQUEST,
  GET_MEETING_QUERY_RESPONSE_SUCCESS,
  GET_MEETING_REQUEST,
  GET_MEETING_SUCCESS,
  GET_MEET_DROPDOWN_DATA_FAILURE,
  GET_MEET_DROPDOWN_DATA_REQUEST,
  GET_MEET_DROPDOWN_DATA_SUCCESS,
  GET_MEET_QUERIES_DETAILS_FAILURE,
  GET_MEET_QUERIES_DETAILS_REQUEST,
  GET_MEET_QUERIES_DETAILS_SUCCESS,
  GET_MEET_QUERIES_FAILURE,
  GET_MEET_QUERIES_REQUEST,
  GET_MEET_QUERIES_SUCCESS,
  GET_PROJECT_DURATION_PIE_CHART__FAILURE,
  GET_PROJECT_DURATION_PIE_CHART__REQUEST,
  GET_PROJECT_DURATION_PIE_CHART__SUCCESS,
  GET_PROJECT_LIST_FAILURE,
  GET_PROJECT_LIST_REQUEST,
  GET_PROJECT_LIST_SUCCESS,
  GET_PROJECT_PIE_CHART__FAILURE,
  GET_PROJECT_PIE_CHART__REQUEST,
  GET_PROJECT_PIE_CHART__SUCCESS,
  GET_RAISED_BY_LIST_QUERIES_FAILURE,
  GET_RAISED_BY_LIST_QUERIES_REQUEST,
  GET_RAISED_BY_LIST_QUERIES_SUCCESS,
  GET_RAISE_BY_LIST_FAILURE,
  GET_RAISE_BY_LIST_REQUEST,
  GET_RAISE_BY_LIST_SUCCESS,
  GET_REASON_DATA__FAILURE,
  GET_REASON_DATA__REQUEST,
  GET_REASON_DATA__SUCCESS,
  GET_RECURRING_FOLLOWUP_SETTINGS_FAILURE,
  GET_RECURRING_FOLLOWUP_SETTINGS_REQUEST,
  GET_RECURRING_FOLLOWUP_SETTINGS_SUCCESS,
  GET_RECURRING_FOLLOWUP__FAILURE,
  GET_RECURRING_FOLLOWUP__REQUEST,
  GET_RECURRING_FOLLOWUP__SUCCESS,
  GET_SETTING_DATA__FAILURE,
  GET_SETTING_DATA__REQUEST,
  GET_SETTING_DATA__SUCCESS,
  GET_STRIKE_AND_REWARDS_LIST_FAILURE,
  GET_STRIKE_AND_REWARDS_LIST_REQUEST,
  GET_STRIKE_AND_REWARDS_LIST_SUCCESS,
  GET_TASK_DROPDOWN_DATA_FAILURE,
  GET_TASK_DROPDOWN_DATA_REQUEST,
  GET_TASK_DROPDOWN_DATA_SUCCESS,
  GET_TASK_FOLLOWUP_DETAILS__FAILURE,
  GET_TASK_FOLLOWUP_DETAILS__REQUEST,
  GET_TASK_FOLLOWUP_DETAILS__SUCCESS,
  GET_TASK_SUMMARY__FAILURE,
  GET_TASK_SUMMARY__REQUEST,
  GET_TASK_SUMMARY__SUCCESS,
  GET_TEAM_DURATION_PIE_CHART__FAILURE,
  GET_TEAM_DURATION_PIE_CHART__REQUEST,
  GET_TEAM_DURATION_PIE_CHART__SUCCESS,
  GET_TEAM_LIST_FAILURE,
  GET_TEAM_LIST_REQUEST,
  GET_TEAM_LIST_SUCCESS,
  GET_TEAM_PIE_CHART__FAILURE,
  GET_TEAM_PIE_CHART__REQUEST,
  GET_TEAM_PIE_CHART__SUCCESS,
  GET_UPCOMING_MEET_FAILURE,
  GET_UPCOMING_MEET_REQUEST,
  GET_UPCOMING_MEET_SUCCESS,
  GET_USERS_BY_TEAM_SELECTION_FAILURE,
  GET_USERS_BY_TEAM_SELECTION_REQUEST,
  GET_USERS_BY_TEAM_SELECTION_SUCCESS,
  HOLD_CALL__FAILURE,
  HOLD_CALL__REQUEST,
  HOLD_CALL__SUCCESS,
  INCOMING_QUERY_BY_STATUS_BAR_CHART__FAILURE,
  INCOMING_QUERY_BY_STATUS_BAR_CHART__REQUEST,
  INCOMING_QUERY_BY_STATUS_BAR_CHART__SUCCESS,
  MARK_AS_COMPLETE__FAILURE,
  MARK_AS_COMPLETE__REQUEST,
  MARK_AS_COMPLETE__SUCCESS,
  MARK_ATTENDEE_ABSENT_PRESENT__FAILURE,
  MARK_ATTENDEE_ABSENT_PRESENT__REQUEST,
  MARK_ATTENDEE_ABSENT_PRESENT__SUCCESS,
  MEETING_DATA_BAR_CHART__FAILURE,
  MEETING_DATA_BAR_CHART__REQUEST,
  MEETING_DATA_BAR_CHART__SUCCESS,
  MEETING_DATA_BY_DURATIONS_CHART__FAILURE,
  MEETING_DATA_BY_DURATIONS_CHART__REQUEST,
  MEETING_DATA_BY_DURATIONS_CHART__SUCCESS,
  MEETING_DATA_STATUS_WISE_BAR_CHART__FAILURE,
  MEETING_DATA_STATUS_WISE_BAR_CHART__REQUEST,
  MEETING_DATA_STATUS_WISE_BAR_CHART__SUCCESS,
  QUERY_RESPONSE_MODE_BAR_CHART__FAILURE,
  QUERY_RESPONSE_MODE_BAR_CHART__REQUEST,
  QUERY_RESPONSE_MODE_BAR_CHART__SUCCESS,
  RECURRING_FOLLOWUP_BY_STATUS_BAR_CHART__FAILURE,
  RECURRING_FOLLOWUP_BY_STATUS_BAR_CHART__REQUEST,
  RECURRING_FOLLOWUP_BY_STATUS_BAR_CHART__SUCCESS,
  RECURRING_FOLLOWUP_DETAILS__FAILURE,
  RECURRING_FOLLOWUP_DETAILS__REQUEST,
  RECURRING_FOLLOWUP_DETAILS__SUCCESS,
  RESCHEDULE_MEETING__FAILURE,
  RESCHEDULE_MEETING__REQUEST,
  RESCHEDULE_MEETING__SUCCESS,
  START_FOLLOWUP_CALL__FAILURE,
  START_FOLLOWUP_CALL__REQUEST,
  START_FOLLOWUP_CALL__SUCCESS,
  STRIKE_AND_REWARD_PROGRESS_BAR__FAILURE,
  STRIKE_AND_REWARD_PROGRESS_BAR__REQUEST,
  STRIKE_AND_REWARD_PROGRESS_BAR__SUCCESS,
  TRANSFER_CALL__FAILURE,
  TRANSFER_CALL__REQUEST,
  TRANSFER_CALL__SUCCESS,
  UNHOLD_CALL__FAILURE,
  UNHOLD_CALL__REQUEST,
  UNHOLD_CALL__SUCCESS,
  USER_LIST_FOR_CALL__FAILURE,
  USER_LIST_FOR_CALL__REQUEST,
  USER_LIST_FOR_CALL__SUCCESS,
} from "../Constants/MeetConstants";

// Meet Dropdown user Reducers
export const MeetDropdownReducers = (state = { dropdown_data: {} }, action) => {
  switch (action.type) {
    case GET_MEET_DROPDOWN_DATA_REQUEST:
      return {
        loading: true,
      };
    case GET_MEET_DROPDOWN_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        dropdown_data: action.payload,
      };
    case GET_MEET_DROPDOWN_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Department List Reducers
export const DepartmentListReducers = (
  state = { department_list: {} },
  action
) => {
  switch (action.type) {
    case GET_DEPARTMENT_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_DEPARTMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        department_list: action.payload,
      };
    case GET_DEPARTMENT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Team List Reducers
export const TeamListReducers = (state = { team_list: {} }, action) => {
  switch (action.type) {
    case GET_TEAM_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_TEAM_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        team_list: action.payload,
      };
    case GET_TEAM_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Project List Reducers
export const ProjectListReducers = (state = { project_list: {} }, action) => {
  switch (action.type) {
    case GET_PROJECT_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        project_list: action.payload,
      };
    case GET_PROJECT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get EA Name List Reducers
export const EANameListReducers = (state = { EA_list: {} }, action) => {
  switch (action.type) {
    case GET_EA_NAME_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_EA_NAME_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        EA_list: action.payload,
      };
    case GET_EA_NAME_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get User Name List Reducers
export const UserNameListReducers = (state = { user_list: {} }, action) => {
  switch (action.type) {
    case GET_RAISE_BY_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_RAISE_BY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        user_list: action.payload,
      };
    case GET_RAISE_BY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Create Recurring Followup Reducers
export const CreateRecurringFollowupReducers = (
  state = { recurring_followup: {} },
  action
) => {
  switch (action.type) {
    case CREATE_RECURRING_FOLLOWUP_REQUEST:
      return {
        loading: true,
      };
    case CREATE_RECURRING_FOLLOWUP_SUCCESS:
      return {
        ...state,
        loading: false,
        recurring_followup: action.payload,
      };
    case CREATE_RECURRING_FOLLOWUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Recurring Followup Settings Reducers
export const getRecurringFollowupSettingsReducers = (
  state = { recurring_followup_data: {} },
  action
) => {
  switch (action.type) {
    case GET_RECURRING_FOLLOWUP_SETTINGS_REQUEST:
      return {
        loading: true,
      };
    case GET_RECURRING_FOLLOWUP_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        recurring_followup_data: action.payload,
      };
    case GET_RECURRING_FOLLOWUP_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Recurring Followup List Reducers
export const getRecurringFollowupListReducers = (
  state = { recurring_followup_list: {} },
  action
) => {
  switch (action.type) {
    case GET_RECURRING_FOLLOWUP__REQUEST:
      return {
        loading: true,
      };
    case GET_RECURRING_FOLLOWUP__SUCCESS:
      return {
        ...state,
        loading: false,
        recurring_followup_list: action.payload,
      };
    case GET_RECURRING_FOLLOWUP__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Users By Teams Reducers
export const getUsersByTeamsReducers = (state = { users_list: {} }, action) => {
  switch (action.type) {
    case GET_USERS_BY_TEAM_SELECTION_REQUEST:
      return {
        loading: true,
      };
    case GET_USERS_BY_TEAM_SELECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        users_list: action.payload,
      };
    case GET_USERS_BY_TEAM_SELECTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Add Meet Reducers
export const AddMeetReducers = (state = { add_meet: {} }, action) => {
  switch (action.type) {
    case ADD_MEETING_REQUEST:
      return {
        loading: true,
      };
    case ADD_MEETING_SUCCESS:
      return {
        ...state,
        loading: false,
        add_meet: action.payload,
      };
    case ADD_MEETING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Add Meet Reducers
export const getMeetListReducers = (state = { meet_list: {} }, action) => {
  switch (action.type) {
    case GET_MEETING_REQUEST:
      return {
        loading: true,
      };
    case GET_MEETING_SUCCESS:
      return {
        ...state,
        loading: false,
        meet_list: action.payload,
      };
    case GET_MEETING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Company List Reducers
export const getCompanyListReducers = (
  state = { company_list: {} },
  action
) => {
  switch (action.type) {
    case GET_COMPANY_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_COMPANY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        company_list: action.payload,
      };
    case GET_COMPANY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Query Rasised By List Reducers
export const getRaisedByListQueryReducers = (
  state = { Query_Raised_List: {} },
  action
) => {
  switch (action.type) {
    case GET_RAISED_BY_LIST_QUERIES_REQUEST:
      return {
        loading: true,
      };
    case GET_RAISED_BY_LIST_QUERIES_SUCCESS:
      return {
        ...state,
        loading: false,
        Query_Raised_List: action.payload,
      };
    case GET_RAISED_BY_LIST_QUERIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Create Queries Reducers
export const createQueriesReducers = (state = { create_Query: {} }, action) => {
  switch (action.type) {
    case CREATE_QUERIES_REQUEST:
      return {
        loading: true,
      };
    case CREATE_QUERIES_SUCCESS:
      return {
        ...state,
        loading: false,
        create_Query: action.payload,
      };
    case CREATE_QUERIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Meet Queries Reducers
export const getMeetQueriesReducers = (
  state = { meet_queries: {} },
  action
) => {
  switch (action.type) {
    case GET_MEET_QUERIES_REQUEST:
      return {
        loading: true,
      };
    case GET_MEET_QUERIES_SUCCESS:
      return {
        ...state,
        loading: false,
        meet_queries: action.payload,
      };
    case GET_MEET_QUERIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Meet Queries Details Reducers
export const getMeetQueriesDetailsReducers = (
  state = { meet_queries_details: {} },
  action
) => {
  switch (action.type) {
    case GET_MEET_QUERIES_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case GET_MEET_QUERIES_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        meet_queries_details: action.payload,
      };
    case GET_MEET_QUERIES_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Upcoming Meet Reducers
export const getUpcomingMeetReducers = (
  state = { upcoming_meet: {} },
  action
) => {
  switch (action.type) {
    case GET_UPCOMING_MEET_REQUEST:
      return {
        loading: true,
      };
    case GET_UPCOMING_MEET_SUCCESS:
      return {
        ...state,
        loading: false,
        upcoming_meet: action.payload,
      };
    case GET_UPCOMING_MEET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Meeting Query Response Reducers
export const getMeetingQueryResponseReducers = (
  state = { query_Response: {} },
  action
) => {
  switch (action.type) {
    case GET_MEETING_QUERY_RESPONSE_REQUEST:
      return {
        loading: true,
      };
    case GET_MEETING_QUERY_RESPONSE_SUCCESS:
      return {
        ...state,
        loading: false,
        query_Response: action.payload,
      };
    case GET_MEETING_QUERY_RESPONSE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Add Meet setting Reducers
export const meetSettingReducers = (state = { meet_setting: {} }, action) => {
  switch (action.type) {
    case ADD_MEET_SETTING_REQUEST:
      return {
        loading: true,
      };
    case ADD_MEET_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        meet_setting: action.payload,
      };
    case ADD_MEET_SETTING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Meeting Details Reducers
export const MeetDetailsReducers = (
  state = { meet_individual_details: {} },
  action
) => {
  switch (action.type) {
    case GET_MEETING_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case GET_MEETING_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        meet_individual_details: action.payload,
      };
    case GET_MEETING_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Change Meeting Status Reducers
export const ChangeMeetingStatusReducers = (
  state = { meet_status: {} },
  action
) => {
  switch (action.type) {
    case CHANGE_MEETING_STATUS_REQUEST:
      return {
        loading: true,
      };
    case CHANGE_MEETING_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        meet_status: action.payload,
      };
    case CHANGE_MEETING_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Strike and Rewards Points Reducers
export const getStrikeandRewardsListReducers = (
  state = { strike_rewards: {} },
  action
) => {
  switch (action.type) {
    case GET_STRIKE_AND_REWARDS_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_STRIKE_AND_REWARDS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        strike_rewards: action.payload,
      };
    case GET_STRIKE_AND_REWARDS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Discussion Meeting dropdown data Reducers
export const getDiscussionMeetingDropdownDataReducers = (
  state = { discussion_data: {} },
  action
) => {
  switch (action.type) {
    case GET_MEETING_DISCCUSSION_DROPDOWN_DATA_REQUEST:
      return {
        loading: true,
      };
    case GET_MEETING_DISCCUSSION_DROPDOWN_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        discussion_data: action.payload,
      };
    case GET_MEETING_DISCCUSSION_DROPDOWN_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Create Discussion Reducers
export const createDiscussionReducers = (
  state = { create_discussion: {} },
  action
) => {
  switch (action.type) {
    case CREATE_DISCUSSION_REQUEST:
      return {
        loading: true,
      };
    case CREATE_DISCUSSION_SUCCESS:
      return {
        ...state,
        loading: false,
        create_discussion: action.payload,
      };
    case CREATE_DISCUSSION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Create Discussion Reducers
export const getCompletedMeetingsReducers = (
  state = { completed_meet: {} },
  action
) => {
  switch (action.type) {
    case GET_COMPLETED_MEETING_REQUEST:
      return {
        loading: true,
      };
    case GET_COMPLETED_MEETING_SUCCESS:
      return {
        ...state,
        loading: false,
        completed_meet: action.payload,
      };
    case GET_COMPLETED_MEETING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Task Dropdown data Reducers
export const getTaskDropdownDataReducers = (
  state = { task_dropdown_data: {} },
  action
) => {
  switch (action.type) {
    case GET_TASK_DROPDOWN_DATA_REQUEST:
      return {
        loading: true,
      };
    case GET_TASK_DROPDOWN_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        task_dropdown_data: action.payload,
      };
    case GET_TASK_DROPDOWN_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Create Task Reducers
export const createTaskReducers = (state = { create_task: {} }, action) => {
  switch (action.type) {
    case CREATE_TASK_REQUEST:
      return {
        loading: true,
      };
    case CREATE_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        create_task: action.payload,
      };
    case CREATE_TASK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Meeting Discussion Point List Reducers
export const getMeetingDiscussionPointListReducers = (
  state = { discussion_point: {} },
  action
) => {
  switch (action.type) {
    case GET_MEETING_DISCUSSION_POINT_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_MEETING_DISCUSSION_POINT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        discussion_point: action.payload,
      };
    case GET_MEETING_DISCUSSION_POINT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Add Followup Dropdown List Reducers
export const getAddFollowupDropdownListReducers = (
  state = { followup_list_dropdown: {} },
  action
) => {
  switch (action.type) {
    case GET_FOLLOWUPS_DROPDOWN_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_FOLLOWUPS_DROPDOWN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        followup_list_dropdown: action.payload,
      };
    case GET_FOLLOWUPS_DROPDOWN_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Add Followup Dropdown List Reducers
export const getAddFollowupListReducers = (
  state = { followup_list: {} },
  action
) => {
  switch (action.type) {
    case GET_FOLLOWUPS_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_FOLLOWUPS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        followup_list: action.payload,
      };
    case GET_FOLLOWUPS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Add Followup Dropdown List Reducers
export const CreateResponseAddReducers = (
  state = { create_ResponseAdd: {} },
  action
) => {
  switch (action.type) {
    case CREATE_RESPONSE_REQUEST:
      return {
        loading: true,
      };
    case CREATE_RESPONSE_SUCCESS:
      return {
        ...state,
        loading: false,
        create_ResponseAdd: action.payload,
      };
    case CREATE_RESPONSE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Cancel Meeting Reducers
export const CancelMeetingReducers = (
  state = { cancel_meeting: {} },
  action
) => {
  switch (action.type) {
    case CANCEL_MEETING__REQUEST:
      return {
        loading: true,
      };
    case CANCEL_MEETING__SUCCESS:
      return {
        ...state,
        loading: false,
        cancel_meeting: action.payload,
      };
    case CANCEL_MEETING__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Team Pie Chart Reducers
export const getTeamPieChartReducers = (
  state = { team_pie_chart: {} },
  action
) => {
  switch (action.type) {
    case GET_TEAM_PIE_CHART__REQUEST:
      return {
        loading: true,
      };
    case GET_TEAM_PIE_CHART__SUCCESS:
      return {
        ...state,
        loading: false,
        team_pie_chart: action.payload,
      };
    case GET_TEAM_PIE_CHART__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Team Pie Chart Duration Reducers
export const getTeamPieChartDurationReducers = (
  state = { team_pie_chart_duration: {} },
  action
) => {
  switch (action.type) {
    case GET_TEAM_DURATION_PIE_CHART__REQUEST:
      return {
        loading: true,
      };
    case GET_TEAM_DURATION_PIE_CHART__SUCCESS:
      return {
        ...state,
        loading: false,
        team_pie_chart_duration: action.payload,
      };
    case GET_TEAM_DURATION_PIE_CHART__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Company Pie Chart Reducers
export const getCompanyPieChartReducers = (
  state = { company_pie_chart: {} },
  action
) => {
  switch (action.type) {
    case GET_COMPANY_PIE_CHART__REQUEST:
      return {
        loading: true,
      };
    case GET_COMPANY_PIE_CHART__SUCCESS:
      return {
        ...state,
        loading: false,
        company_pie_chart: action.payload,
      };
    case GET_COMPANY_PIE_CHART__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Company Pie Chart Duration Reducers
export const getCompanyPieChartDurationReducers = (
  state = { company_pie_chart_duration: {} },
  action
) => {
  switch (action.type) {
    case GET_COMPANY_DURATION_PIE_CHART__REQUEST:
      return {
        loading: true,
      };
    case GET_COMPANY_DURATION_PIE_CHART__SUCCESS:
      return {
        ...state,
        loading: false,
        company_pie_chart_duration: action.payload,
      };
    case GET_COMPANY_DURATION_PIE_CHART__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Project Pie Chart Reducers
export const getProjectPieChartReducers = (
  state = { project_pie_chart: {} },
  action
) => {
  switch (action.type) {
    case GET_PROJECT_PIE_CHART__REQUEST:
      return {
        loading: true,
      };
    case GET_PROJECT_PIE_CHART__SUCCESS:
      return {
        ...state,
        loading: false,
        project_pie_chart: action.payload,
      };
    case GET_PROJECT_PIE_CHART__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Project Pie Chart Duration Reducers
export const getProjectPieChartDurationReducers = (
  state = { project_pie_chart_duration: {} },
  action
) => {
  switch (action.type) {
    case GET_PROJECT_DURATION_PIE_CHART__REQUEST:
      return {
        loading: true,
      };
    case GET_PROJECT_DURATION_PIE_CHART__SUCCESS:
      return {
        ...state,
        loading: false,
        project_pie_chart_duration: action.payload,
      };
    case GET_PROJECT_DURATION_PIE_CHART__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Project Pie Chart Duration Reducers
export const getMeetingDataGroupedBarReducers = (
  state = { meeting_data_grouped: {} },
  action
) => {
  switch (action.type) {
    case MEETING_DATA_BAR_CHART__REQUEST:
      return {
        loading: true,
      };
    case MEETING_DATA_BAR_CHART__SUCCESS:
      return {
        ...state,
        loading: false,
        meeting_data_grouped: action.payload,
      };
    case MEETING_DATA_BAR_CHART__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Followup call data  Reducers
export const getFollowupGroupedBarReducers = (
  state = { followup_calls_grouped: {} },
  action
) => {
  switch (action.type) {
    case FOLLOWUP_CALL_BAR_CHART__REQUEST:
      return {
        loading: true,
      };
    case FOLLOWUP_CALL_BAR_CHART__SUCCESS:
      return {
        ...state,
        loading: false,
        followup_calls_grouped: action.payload,
      };
    case FOLLOWUP_CALL_BAR_CHART__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// discussion point type bar reducers
export const getDiscussionPointTypeReducers = (
  state = { discussion_point_type: {} },
  action
) => {
  switch (action.type) {
    case DISCUSSION_POINT_TYPES_BAR_CHART__REQUEST:
      return {
        loading: true,
      };
    case DISCUSSION_POINT_TYPES_BAR_CHART__SUCCESS:
      return {
        ...state,
        loading: false,
        discussion_point_type: action.payload,
      };
    case DISCUSSION_POINT_TYPES_BAR_CHART__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Recurring followup by status bar reducers
export const recurringFollowupByStatusBarReducers = (
  state = { recurring_followupStatus: {} },
  action
) => {
  switch (action.type) {
    case RECURRING_FOLLOWUP_BY_STATUS_BAR_CHART__REQUEST:
      return {
        loading: true,
      };
    case RECURRING_FOLLOWUP_BY_STATUS_BAR_CHART__SUCCESS:
      return {
        ...state,
        loading: false,
        recurring_followupStatus: action.payload,
      };
    case RECURRING_FOLLOWUP_BY_STATUS_BAR_CHART__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Meeting Data Status Wise bar reducers
export const MeetingDataStatusWiseBarReducers = (
  state = { meet_data_status_wise: {} },
  action
) => {
  switch (action.type) {
    case MEETING_DATA_STATUS_WISE_BAR_CHART__REQUEST:
      return {
        loading: true,
      };
    case MEETING_DATA_STATUS_WISE_BAR_CHART__SUCCESS:
      return {
        ...state,
        loading: false,
        meet_data_status_wise: action.payload,
      };
    case MEETING_DATA_STATUS_WISE_BAR_CHART__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Actionable Discussion Points bar reducers
export const ActionableDiscussionPointsBarReducers = (
  state = { actionable_points: {} },
  action
) => {
  switch (action.type) {
    case ACTIONABLE_DISCUSSION_POINTS_BAR_CHART__REQUEST:
      return {
        loading: true,
      };
    case ACTIONABLE_DISCUSSION_POINTS_BAR_CHART__SUCCESS:
      return {
        ...state,
        loading: false,
        actionable_points: action.payload,
      };
    case ACTIONABLE_DISCUSSION_POINTS_BAR_CHART__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Query Response Mode bar reducers
export const QueryResponseModeBarReducers = (
  state = { query_data: {} },
  action
) => {
  switch (action.type) {
    case QUERY_RESPONSE_MODE_BAR_CHART__REQUEST:
      return {
        loading: true,
      };
    case QUERY_RESPONSE_MODE_BAR_CHART__SUCCESS:
      return {
        ...state,
        loading: false,
        query_data: action.payload,
      };
    case QUERY_RESPONSE_MODE_BAR_CHART__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Incoming Query Status bar reducers
export const IncomingQueryBarReducers = (
  state = { IncomingData: {} },
  action
) => {
  switch (action.type) {
    case INCOMING_QUERY_BY_STATUS_BAR_CHART__REQUEST:
      return {
        loading: true,
      };
    case INCOMING_QUERY_BY_STATUS_BAR_CHART__SUCCESS:
      return {
        ...state,
        loading: false,
        IncomingData: action.payload,
      };
    case INCOMING_QUERY_BY_STATUS_BAR_CHART__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Meeting Data By Durations bar reducers
export const MeetingDataByDurationsBarReducers = (
  state = { meeting_durations: {} },
  action
) => {
  switch (action.type) {
    case MEETING_DATA_BY_DURATIONS_CHART__REQUEST:
      return {
        loading: true,
      };
    case MEETING_DATA_BY_DURATIONS_CHART__SUCCESS:
      return {
        ...state,
        loading: false,
        meeting_durations: action.payload,
      };
    case MEETING_DATA_BY_DURATIONS_CHART__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Strike and rewards Progress bar reducers
export const StrikeandRewardsProgressBarReducers = (
  state = { strike_rewards_progress: {} },
  action
) => {
  switch (action.type) {
    case STRIKE_AND_REWARD_PROGRESS_BAR__REQUEST:
      return {
        loading: true,
      };
    case STRIKE_AND_REWARD_PROGRESS_BAR__SUCCESS:
      return {
        ...state,
        loading: false,
        strike_rewards_progress: action.payload,
      };
    case STRIKE_AND_REWARD_PROGRESS_BAR__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Conclude Followup reducers
export const ConcludeFollowupReducers = (
  state = { conclude_followup: {} },
  action
) => {
  switch (action.type) {
    case CONCLUDE_FOLLOWUP__REQUEST:
      return {
        loading: true,
      };
    case CONCLUDE_FOLLOWUP__SUCCESS:
      return {
        ...state,
        loading: false,
        conclude_followup: action.payload,
      };
    case CONCLUDE_FOLLOWUP__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Add Followup Calls reducers
export const AddFollowupCallsReducers = (state = { add_calls: {} }, action) => {
  switch (action.type) {
    case ADD_FOLLOWUP_CALL__REQUEST:
      return {
        loading: true,
      };
    case ADD_FOLLOWUP_CALL__SUCCESS:
      return {
        ...state,
        loading: false,
        add_calls: action.payload,
      };
    case ADD_FOLLOWUP_CALL__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Ea Designation User reducers
export const EADesignationSettingReducers = (
  state = { designation_setting: {} },
  action
) => {
  switch (action.type) {
    case EA_DESIGNATION_USER__REQUEST:
      return {
        loading: true,
      };
    case EA_DESIGNATION_USER__SUCCESS:
      return {
        ...state,
        loading: false,
        designation_setting: action.payload,
      };
    case EA_DESIGNATION_USER__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Add Followup Remark reducers
export const AddFollowupRemarkReducers = (
  state = { followup_Remark: {} },
  action
) => {
  switch (action.type) {
    case ADD_FOLLOWUP_REMARK__REQUEST:
      return {
        loading: true,
      };
    case ADD_FOLLOWUP_REMARK__SUCCESS:
      return {
        ...state,
        loading: false,
        followup_Remark: action.payload,
      };
    case ADD_FOLLOWUP_REMARK__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Dashboard Metrics reducers
export const getDashboardMetricsReducers = (
  state = { dashboard_Metrics: {} },
  action
) => {
  switch (action.type) {
    case GET_DASHBOARD_METRICS__REQUEST:
      return {
        loading: true,
      };
    case GET_DASHBOARD_METRICS__SUCCESS:
      return {
        ...state,
        loading: false,
        dashboard_Metrics: action.payload,
      };
    case GET_DASHBOARD_METRICS__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Followups Dropdown Data reducers
export const getFollowupsDropdownDataReducers = (
  state = { followup_dropdown_data: {} },
  action
) => {
  switch (action.type) {
    case GET_CREATE_FOLLOWUPS_DROPDOWN__REQUEST:
      return {
        loading: true,
      };
    case GET_CREATE_FOLLOWUPS_DROPDOWN__SUCCESS:
      return {
        ...state,
        loading: false,
        followup_dropdown_data: action.payload,
      };
    case GET_CREATE_FOLLOWUPS_DROPDOWN__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Add Create Followup Meeting reducers
export const AddCreateFollowupMeetingReducers = (
  state = { add_create_followup_meet: {} },
  action
) => {
  switch (action.type) {
    case ADD_CREATE_FOLLOWUP_MEETING__REQUEST:
      return {
        loading: true,
      };
    case ADD_CREATE_FOLLOWUP_MEETING__SUCCESS:
      return {
        ...state,
        loading: false,
        add_create_followup_meet: action.payload,
      };
    case ADD_CREATE_FOLLOWUP_MEETING__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Discussion Detail reducers
export const GetDiscussionDetailsReducers = (
  state = { dicussion_detail: {} },
  action
) => {
  switch (action.type) {
    case GET_DISCUSSION_DETAIL__REQUEST:
      return {
        loading: true,
      };
    case GET_DISCUSSION_DETAIL__SUCCESS:
      return {
        ...state,
        loading: false,
        dicussion_detail: action.payload,
      };
    case GET_DISCUSSION_DETAIL__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Task Summary reducers
export const GetTaskSummaryReducers = (
  state = { task_details_Summary: {} },
  action
) => {
  switch (action.type) {
    case GET_TASK_SUMMARY__REQUEST:
      return {
        loading: true,
      };
    case GET_TASK_SUMMARY__SUCCESS:
      return {
        ...state,
        loading: false,
        task_details_Summary: action.payload,
      };
    case GET_TASK_SUMMARY__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Rescheduled Meeting reducers
export const RescheduledMeetingReducers = (
  state = { reschedule_meet: {} },
  action
) => {
  switch (action.type) {
    case RESCHEDULE_MEETING__REQUEST:
      return {
        loading: true,
      };
    case RESCHEDULE_MEETING__SUCCESS:
      return {
        ...state,
        loading: false,
        reschedule_meet: action.payload,
      };
    case RESCHEDULE_MEETING__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Recurring Followup Details reducers
export const RecurringFollowupDetailsReducers = (
  state = { recurring_followup_details: {} },
  action
) => {
  switch (action.type) {
    case RECURRING_FOLLOWUP_DETAILS__REQUEST:
      return {
        loading: true,
      };
    case RECURRING_FOLLOWUP_DETAILS__SUCCESS:
      return {
        ...state,
        loading: false,
        recurring_followup_details: action.payload,
      };
    case RECURRING_FOLLOWUP_DETAILS__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Mark as Complete reducers
export const MarkasCompleteReducers = (
  state = { complete_queries: {} },
  action
) => {
  switch (action.type) {
    case MARK_AS_COMPLETE__REQUEST:
      return {
        loading: true,
      };
    case MARK_AS_COMPLETE__SUCCESS:
      return {
        ...state,
        loading: false,
        complete_queries: action.payload,
      };
    case MARK_AS_COMPLETE__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Task Followup Data reducers
export const getTaskFollowupDataReducers = (
  state = { task_followups: {} },
  action
) => {
  switch (action.type) {
    case GET_TASK_FOLLOWUP_DETAILS__REQUEST:
      return {
        loading: true,
      };
    case GET_TASK_FOLLOWUP_DETAILS__SUCCESS:
      return {
        ...state,
        loading: false,
        task_followups: action.payload,
      };
    case GET_TASK_FOLLOWUP_DETAILS__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Task Followup Data reducers
export const getFollowupCallIdReducers = (
  state = { followup_call_id: {} },
  action
) => {
  switch (action.type) {
    case GET_FOLLOWUP_CALL_ID__REQUEST:
      return {
        loading: true,
      };
    case GET_FOLLOWUP_CALL_ID__SUCCESS:
      return {
        ...state,
        loading: false,
        followup_call_id: action.payload,
      };
    case GET_FOLLOWUP_CALL_ID__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Attendee List reducers
export const getAttendeeListReducers = (
  state = { attendee_list: {} },
  action
) => {
  switch (action.type) {
    case GET_ATTENDEE_LIST__REQUEST:
      return {
        loading: true,
      };
    case GET_ATTENDEE_LIST__SUCCESS:
      return {
        ...state,
        loading: false,
        attendee_list: action.payload,
      };
    case GET_ATTENDEE_LIST__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Mark Attendee Absent or present reducers
export const MarkAttendeeAbsentPresentReducers = (
  state = { mark_attendee: {} },
  action
) => {
  switch (action.type) {
    case MARK_ATTENDEE_ABSENT_PRESENT__REQUEST:
      return {
        loading: true,
      };
    case MARK_ATTENDEE_ABSENT_PRESENT__SUCCESS:
      return {
        ...state,
        loading: false,
        mark_attendee: action.payload,
      };
    case MARK_ATTENDEE_ABSENT_PRESENT__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Setting data reducers
export const getSettingdataReducers = (
  state = { settings_data: {} },
  action
) => {
  switch (action.type) {
    case GET_SETTING_DATA__REQUEST:
      return {
        loading: true,
      };
    case GET_SETTING_DATA__SUCCESS:
      return {
        ...state,
        loading: false,
        settings_data: action.payload,
      };
    case GET_SETTING_DATA__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Reason Data reducers
export const getReasindataReducers = (state = { reason_data: {} }, action) => {
  switch (action.type) {
    case GET_REASON_DATA__REQUEST:
      return {
        loading: true,
      };
    case GET_REASON_DATA__SUCCESS:
      return {
        ...state,
        loading: false,
        reason_data: action.payload,
      };
    case GET_REASON_DATA__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Delete Recurring Setup reducers
export const deleteRecurringSetupReducers = (
  state = { del_recurring_setup: {} },
  action
) => {
  switch (action.type) {
    case DELETE_RECURRING_SETUP_DATA__REQUEST:
      return {
        loading: true,
      };
    case DELETE_RECURRING_SETUP_DATA__SUCCESS:
      return {
        ...state,
        loading: false,
        del_recurring_setup: action.payload,
      };
    case DELETE_RECURRING_SETUP_DATA__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Edit Update Meeting reducers
export const EditUpdateMeetingReducers = (
  state = { update_Meeting: {} },
  action
) => {
  switch (action.type) {
    case EDIT_UPDATE_MEETING__REQUEST:
      return {
        loading: true,
      };
    case EDIT_UPDATE_MEETING__SUCCESS:
      return {
        ...state,
        loading: false,
        update_Meeting: action.payload,
      };
    case EDIT_UPDATE_MEETING__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Call Create Data reducers
export const getCallCreateDataReducers = (
  state = { call_create: {} },
  action
) => {
  switch (action.type) {
    case GET_CALL_CREATE_DATA__REQUEST:
      return {
        loading: true,
      };
    case GET_CALL_CREATE_DATA__SUCCESS:
      return {
        ...state,
        loading: false,
        call_create: action.payload,
      };
    case GET_CALL_CREATE_DATA__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Followups Call Summary Data reducers
export const getFollowupCallSummaryReducers = (
  state = { followups_call_summary: {} },
  action
) => {
  switch (action.type) {
    case GET_FOLLOWUPS_CALL_SUMMARY__REQUEST:
      return {
        loading: true,
      };
    case GET_FOLLOWUPS_CALL_SUMMARY__SUCCESS:
      return {
        ...state,
        loading: false,
        followups_call_summary: action.payload,
      };
    case GET_FOLLOWUPS_CALL_SUMMARY__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Start Followup Calls Reducers
export const startFollowupCallReducers = (
  state = { start_Call: {} },
  action
) => {
  switch (action.type) {
    case START_FOLLOWUP_CALL__REQUEST:
      return {
        loading: true,
      };
    case START_FOLLOWUP_CALL__SUCCESS:
      return {
        ...state,
        loading: false,
        start_Call: action.payload,
      };
    case START_FOLLOWUP_CALL__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Followups Call Details Reducers
export const followupCallDetailsReducers = (
  state = { followup_call_details: {} },
  action
) => {
  switch (action.type) {
    case FOLLOWUP_CALL_DETAILS__REQUEST:
      return {
        loading: true,
      };
    case FOLLOWUP_CALL_DETAILS__SUCCESS:
      return {
        ...state,
        loading: false,
        followup_call_details: action.payload,
      };
    case FOLLOWUP_CALL_DETAILS__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// hold Call Reducers
export const holdCallReducers = (state = { hold_call: {} }, action) => {
  switch (action.type) {
    case HOLD_CALL__REQUEST:
      return {
        loading: true,
      };
    case HOLD_CALL__SUCCESS:
      return {
        ...state,
        loading: false,
        hold_call: action.payload,
      };
    case HOLD_CALL__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Unhold Call Reducers
export const UnholdCallReducers = (state = { Unhold_call: {} }, action) => {
  switch (action.type) {
    case UNHOLD_CALL__REQUEST:
      return {
        loading: true,
      };
    case UNHOLD_CALL__SUCCESS:
      return {
        ...state,
        loading: false,
        Unhold_call: action.payload,
      };
    case UNHOLD_CALL__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// End Call Reducers
export const EndCallReducers = (state = { end_call: {} }, action) => {
  switch (action.type) {
    case END_CALL__REQUEST:
      return {
        loading: true,
      };
    case END_CALL__SUCCESS:
      return {
        ...state,
        loading: false,
        end_call: action.payload,
      };
    case END_CALL__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// User List For Call Reducers
export const userListForCallReducers = (
  state = { user_list_call: {} },
  action
) => {
  switch (action.type) {
    case USER_LIST_FOR_CALL__REQUEST:
      return {
        loading: true,
      };
    case USER_LIST_FOR_CALL__SUCCESS:
      return {
        ...state,
        loading: false,
        user_list_call: action.payload,
      };
    case USER_LIST_FOR_CALL__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Transfer Call Reducers
export const TransferCallReducers = (state = { transfer: {} }, action) => {
  switch (action.type) {
    case TRANSFER_CALL__REQUEST:
      return {
        loading: true,
      };
    case TRANSFER_CALL__SUCCESS:
      return {
        ...state,
        loading: false,
        transfer: action.payload,
      };
    case TRANSFER_CALL__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Conference Call Reducers
export const ConferenceCallReducers = (state = { conference: {} }, action) => {
  switch (action.type) {
    case CONFERENCE_CALL__REQUEST:
      return {
        loading: true,
      };
    case CONFERENCE_CALL__SUCCESS:
      return {
        ...state,
        loading: false,
        conference: action.payload,
      };
    case CONFERENCE_CALL__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Followup Call Details List Reducers
export const FollowupCallDetailsListReducers = (
  state = { followup_details_list: {} },
  action
) => {
  switch (action.type) {
    case FOLLOWUP_CALL_DETAILS_LIST_DATA__REQUEST:
      return {
        loading: true,
      };
    case FOLLOWUP_CALL_DETAILS_LIST_DATA__SUCCESS:
      return {
        ...state,
        loading: false,
        followup_details_list: action.payload,
      };
    case FOLLOWUP_CALL_DETAILS_LIST_DATA__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get followup call IDS Reducers
export const getFollowupCallIdsReducers = (
  state = { followups_call_ids: {} },
  action
) => {
  switch (action.type) {
    case GET_FOLLOWUP_CALL_IDS__REQUEST:
      return {
        loading: true,
      };
    case GET_FOLLOWUP_CALL_IDS__SUCCESS:
      return {
        ...state,
        loading: false,
        followups_call_ids: action.payload,
      };
    case GET_FOLLOWUP_CALL_IDS__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
