import { LoginUserReducers } from "./Reducers/LoginReducers";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { thunk } from "redux-thunk";
import {
  ActionableDiscussionPointsBarReducers,
  AddCreateFollowupMeetingReducers,
  AddFollowupCallsReducers,
  AddFollowupRemarkReducers,
  AddMeetReducers,
  CancelMeetingReducers,
  ChangeMeetingStatusReducers,
  ConcludeFollowupReducers,
  ConferenceCallReducers,
  CreateRecurringFollowupReducers,
  CreateResponseAddReducers,
  DepartmentListReducers,
  EADesignationSettingReducers,
  EANameListReducers,
  EditUpdateMeetingReducers,
  EndCallReducers,
  FollowupCallDetailsListReducers,
  GetDiscussionDetailsReducers,
  GetTaskSummaryReducers,
  IncomingQueryBarReducers,
  MarkAttendeeAbsentPresentReducers,
  MarkasCompleteReducers,
  MeetDetailsReducers,
  MeetDropdownReducers,
  MeetingDataByDurationsBarReducers,
  MeetingDataStatusWiseBarReducers,
  ProjectListReducers,
  QueryResponseModeBarReducers,
  RecurringFollowupDetailsReducers,
  RescheduledMeetingReducers,
  StrikeandRewardsProgressBarReducers,
  TeamListReducers,
  TransferCallReducers,
  UnholdCallReducers,
  UserNameListReducers,
  createDiscussionReducers,
  createQueriesReducers,
  createTaskReducers,
  deleteRecurringSetupReducers,
  followupCallDetailsReducers,
  getAddFollowupDropdownListReducers,
  getAddFollowupListReducers,
  getAttendeeListReducers,
  getCallCreateDataReducers,
  getCompanyListReducers,
  getCompanyPieChartDurationReducers,
  getCompanyPieChartReducers,
  getCompletedMeetingsReducers,
  getDashboardMetricsReducers,
  getDiscussionMeetingDropdownDataReducers,
  getDiscussionPointTypeReducers,
  getFollowupCallIdReducers,
  getFollowupCallIdsReducers,
  getFollowupCallSummaryReducers,
  getFollowupGroupedBarReducers,
  getFollowupsDropdownDataReducers,
  getMeetListReducers,
  getMeetQueriesDetailsReducers,
  getMeetQueriesReducers,
  getMeetingDataGroupedBarReducers,
  getMeetingDiscussionPointListReducers,
  getMeetingQueryResponseReducers,
  getProjectPieChartDurationReducers,
  getProjectPieChartReducers,
  getRaisedByListQueryReducers,
  getReasindataReducers,
  getRecurringFollowupListReducers,
  getRecurringFollowupSettingsReducers,
  getSettingdataReducers,
  getStrikeandRewardsListReducers,
  getTaskDropdownDataReducers,
  getTaskFollowupDataReducers,
  getTeamPieChartDurationReducers,
  getTeamPieChartReducers,
  getUpcomingMeetReducers,
  getUsersByTeamsReducers,
  holdCallReducers,
  meetSettingReducers,
  recurringFollowupByStatusBarReducers,
  startFollowupCallReducers,
  userListForCallReducers,
} from "./Reducers/MeetReducers";

const reducer = combineReducers({
  login: LoginUserReducers,
  dropdown_Meet: MeetDropdownReducers,
  departmentList: DepartmentListReducers,
  teamList: TeamListReducers,
  projectList: ProjectListReducers,
  EAlist: EANameListReducers,
  userList: UserNameListReducers,
  RecurredCreate: CreateRecurringFollowupReducers,
  ReccurredDataSettings: getRecurringFollowupSettingsReducers,
  reuccringFollowupList: getRecurringFollowupListReducers,
  userListTeams: getUsersByTeamsReducers,
  addMeeting: AddMeetReducers,
  MeetList: getMeetListReducers,
  companyListData: getCompanyListReducers,
  raisedByListQuery: getRaisedByListQueryReducers,
  CreateIncomingQueries: createQueriesReducers,
  MeetQueries: getMeetQueriesReducers,
  QueriesDetails: getMeetQueriesDetailsReducers,
  upcomingMeet: getUpcomingMeetReducers,
  QueryList: getMeetingQueryResponseReducers,
  AddMeetset: meetSettingReducers,
  MeetingDetails: MeetDetailsReducers,
  changeMeetStatus: ChangeMeetingStatusReducers,
  StrikeList: getStrikeandRewardsListReducers,
  meetDsDropdown: getDiscussionMeetingDropdownDataReducers,
  createDiscussionData: createDiscussionReducers,
  completedMeeting: getCompletedMeetingsReducers,
  taskDropdowndata: getTaskDropdownDataReducers,
  AddTask: createTaskReducers,
  MeetDisucssionPoint: getMeetingDiscussionPointListReducers,
  followupDropdownList: getAddFollowupDropdownListReducers,
  followupListTable: getAddFollowupListReducers,
  AddResponse: CreateResponseAddReducers,
  cancelMeeting: CancelMeetingReducers,
  teamPieChart: getTeamPieChartReducers,
  teamPieChartDuration: getTeamPieChartDurationReducers,
  companyPieChart: getCompanyPieChartReducers,
  companyPieChartDuration: getCompanyPieChartDurationReducers,
  projectPieChart: getProjectPieChartReducers,
  projectPieChartDuration: getProjectPieChartDurationReducers,
  meetingDataBarChart: getMeetingDataGroupedBarReducers,
  followupCallBarChart: getFollowupGroupedBarReducers,
  discussionBarChart: getDiscussionPointTypeReducers,
  recurringFollowupBar: recurringFollowupByStatusBarReducers,
  MeetDataStatusWise: MeetingDataStatusWiseBarReducers,
  ActionableDisucssionPoint: ActionableDiscussionPointsBarReducers,
  QueryResponseData: QueryResponseModeBarReducers,
  IncomingQueriesData: IncomingQueryBarReducers,
  MeetDataDuration: MeetingDataByDurationsBarReducers,
  StrikeRewardsProgress: StrikeandRewardsProgressBarReducers,
  FollowupConclude: ConcludeFollowupReducers,
  FolowupCalls: AddFollowupCallsReducers,
  EADesignationSetting: EADesignationSettingReducers,
  followupRemark: AddFollowupRemarkReducers,
  DashboardData: getDashboardMetricsReducers,
  followupsCreateDropdownData: getFollowupsDropdownDataReducers,
  AddCreateFollowupMeets: AddCreateFollowupMeetingReducers,
  dicussionDetails: GetDiscussionDetailsReducers,
  TaskSummary: GetTaskSummaryReducers,
  rescheduleMeeting: RescheduledMeetingReducers,
  RecurringFDetails: RecurringFollowupDetailsReducers,
  completeQuery: MarkasCompleteReducers,
  TaskFollowupData: getTaskFollowupDataReducers,
  followupCallIds: getFollowupCallIdReducers,
  AttendeeList: getAttendeeListReducers,
  MarkAttendess: MarkAttendeeAbsentPresentReducers,
  SettingsData: getSettingdataReducers,
  ReasonsStrike: getReasindataReducers,
  deleteRecurringSetup: deleteRecurringSetupReducers,
  editMeeting: EditUpdateMeetingReducers,
  CallCreateData: getCallCreateDataReducers,
  followupsCallSummary: getFollowupCallSummaryReducers,
  StartCall: startFollowupCallReducers,
  followupCallD: followupCallDetailsReducers,
  holdCalls: holdCallReducers,
  unHoldCalls: UnholdCallReducers,
  endCalls: EndCallReducers,
  userListsCall: userListForCallReducers,
  conferenceCall: ConferenceCallReducers,
  transferCall: TransferCallReducers,
  FollowupScallList: FollowupCallDetailsListReducers,
  followupCallId: getFollowupCallIdsReducers,
});

const middleware = [thunk];
const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
