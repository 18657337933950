export const GET_MEET_DROPDOWN_DATA_REQUEST = "GET_MEET_DROPDOWN_DATA_REQUEST";
export const GET_MEET_DROPDOWN_DATA_SUCCESS = "GET_MEET_DROPDOWN_DATA_SUCCESS";
export const GET_MEET_DROPDOWN_DATA_FAILURE = "GET_MEET_DROPDOWN_DATA_FAILURE";

export const GET_DEPARTMENT_LIST_REQUEST = "GET_DEPARTMENT_LIST_REQUEST";
export const GET_DEPARTMENT_LIST_SUCCESS = "GET_DEPARTMENT_LIST_SUCCESS";
export const GET_DEPARTMENT_LIST_FAILURE = "GET_DEPARTMENT_LIST_FAILURE";

export const GET_TEAM_LIST_REQUEST = "GET_TEAM_LIST_REQUEST";
export const GET_TEAM_LIST_SUCCESS = "GET_TEAM_LIST_SUCCESS";
export const GET_TEAM_LIST_FAILURE = "GET_TEAM_LIST_FAILURE";

export const GET_PROJECT_LIST_REQUEST = "GET_PROJECT_LIST_REQUEST";
export const GET_PROJECT_LIST_SUCCESS = "GET_PROJECT_LIST_SUCCESS";
export const GET_PROJECT_LIST_FAILURE = "GET_PROJECT_LIST_FAILURE";

export const GET_EA_NAME_LIST_REQUEST = "GET_EA_NAME_LIST_REQUEST";
export const GET_EA_NAME_LIST_SUCCESS = "GET_EA_NAME_LIST_SUCCESS";
export const GET_EA_NAME_LIST_FAILURE = "GET_EA_NAME_LIST_FAILURE";

export const GET_RAISE_BY_LIST_REQUEST = "GET_RAISE_BY_LIST_REQUEST";
export const GET_RAISE_BY_LIST_SUCCESS = "GET_RAISE_BY_LIST_SUCCESS";
export const GET_RAISE_BY_LIST_FAILURE = "GET_RAISE_BY_LIST_FAILURE";

export const CREATE_RECURRING_FOLLOWUP_REQUEST =
  "CREATE_RECURRING_FOLLOWUP_REQUEST";
export const CREATE_RECURRING_FOLLOWUP_SUCCESS =
  "CREATE_RECURRING_FOLLOWUP_SUCCESS";
export const CREATE_RECURRING_FOLLOWUP_FAILURE =
  "CREATE_RECURRING_FOLLOWUP_FAILURE";

export const GET_RECURRING_FOLLOWUP_SETTINGS_REQUEST =
  "GET_RECURRING_FOLLOWUP_SETTINGS_REQUEST";
export const GET_RECURRING_FOLLOWUP_SETTINGS_SUCCESS =
  "GET_RECURRING_FOLLOWUP_SETTINGS_SUCCESS";
export const GET_RECURRING_FOLLOWUP_SETTINGS_FAILURE =
  "GET_RECURRING_FOLLOWUP_SETTINGS_FAILURE";

export const GET_RECURRING_FOLLOWUP__REQUEST =
  "GET_RECURRING_FOLLOWUP__REQUEST";
export const GET_RECURRING_FOLLOWUP__SUCCESS =
  "GET_RECURRING_FOLLOWUP__SUCCESS";
export const GET_RECURRING_FOLLOWUP__FAILURE =
  "GET_RECURRING_FOLLOWUP__FAILURE";

export const GET_USERS_BY_TEAM_SELECTION_REQUEST =
  "GET_USERS_BY_TEAM_SELECTION_REQUEST";
export const GET_USERS_BY_TEAM_SELECTION_SUCCESS =
  "GET_USERS_BY_TEAM_SELECTION_SUCCESS";
export const GET_USERS_BY_TEAM_SELECTION_FAILURE =
  "GET_USERS_BY_TEAM_SELECTION_FAILURE";

export const ADD_MEETING_REQUEST = "ADD_MEETING_REQUEST";
export const ADD_MEETING_SUCCESS = "ADD_MEETING_SUCCESS";
export const ADD_MEETING_FAILURE = "ADD_MEETING_FAILURE";

export const GET_MEETING_REQUEST = "GET_MEETING_REQUEST";
export const GET_MEETING_SUCCESS = "GET_MEETING_SUCCESS";
export const GET_MEETING_FAILURE = "GET_MEETING_FAILURE";

export const GET_COMPANY_LIST_REQUEST = "GET_COMPANY_LIST_REQUEST";
export const GET_COMPANY_LIST_SUCCESS = "GET_COMPANY_LIST_SUCCESS";
export const GET_COMPANY_LIST_FAILURE = "GET_COMPANY_LIST_FAILURE";

export const GET_RAISED_BY_LIST_QUERIES_REQUEST =
  "GET_RAISED_BY_LIST_QUERIES_REQUEST";
export const GET_RAISED_BY_LIST_QUERIES_SUCCESS =
  "GET_RAISED_BY_LIST_QUERIES_SUCCESS";
export const GET_RAISED_BY_LIST_QUERIES_FAILURE =
  "GET_RAISED_BY_LIST_QUERIES_FAILURE";

export const CREATE_QUERIES_REQUEST = "CREATE_QUERIES_REQUEST";
export const CREATE_QUERIES_SUCCESS = "CREATE_QUERIES_SUCCESS";
export const CREATE_QUERIES_FAILURE = "CREATE_QUERIES_FAILURE";

export const GET_MEET_QUERIES_REQUEST = "GET_MEET_QUERIES_REQUEST";
export const GET_MEET_QUERIES_SUCCESS = "GET_MEET_QUERIES_SUCCESS";
export const GET_MEET_QUERIES_FAILURE = "GET_MEET_QUERIES_FAILURE";

export const GET_MEET_QUERIES_DETAILS_REQUEST =
  "GET_MEET_QUERIES_DETAILS_REQUEST";
export const GET_MEET_QUERIES_DETAILS_SUCCESS =
  "GET_MEET_QUERIES_DETAILS_SUCCESS";
export const GET_MEET_QUERIES_DETAILS_FAILURE =
  "GET_MEET_QUERIES_DETAILS_FAILURE";

export const GET_UPCOMING_MEET_REQUEST = "GET_UPCOMING_MEET_REQUEST";
export const GET_UPCOMING_MEET_SUCCESS = "GET_UPCOMING_MEET_SUCCESS";
export const GET_UPCOMING_MEET_FAILURE = "GET_UPCOMING_MEET_FAILURE";

export const GET_MEETING_QUERY_RESPONSE_REQUEST =
  "GET_MEETING_QUERY_RESPONSE_REQUEST";
export const GET_MEETING_QUERY_RESPONSE_SUCCESS =
  "GET_MEETING_QUERY_RESPONSE_SUCCESS";
export const GET_MEETING_QUERY_RESPONSE_FAILURE =
  "GET_MEETING_QUERY_RESPONSE_FAILURE";

export const ADD_MEET_SETTING_REQUEST = "ADD_MEET_SETTING_REQUEST";
export const ADD_MEET_SETTING_SUCCESS = "ADD_MEET_SETTING_SUCCESS";
export const ADD_MEET_SETTING_FAILURE = "ADD_MEET_SETTING_FAILURE";

export const GET_MEETING_DETAILS_REQUEST = "GET_MEETING_DETAILS_REQUEST";
export const GET_MEETING_DETAILS_SUCCESS = "GET_MEETING_DETAILS_SUCCESS";
export const GET_MEETING_DETAILS_FAILURE = "GET_MEETING_DETAILS_FAILURE";

export const CHANGE_MEETING_STATUS_REQUEST = "CHANGE_MEETING_STATUS_REQUEST";
export const CHANGE_MEETING_STATUS_SUCCESS = "CHANGE_MEETING_STATUS_SUCCESS";
export const CHANGE_MEETING_STATUS_FAILURE = "CHANGE_MEETING_STATUS_FAILURE";

export const GET_STRIKE_AND_REWARDS_LIST_REQUEST =
  "GET_STRIKE_AND_REWARDS_LIST_REQUEST";
export const GET_STRIKE_AND_REWARDS_LIST_SUCCESS =
  "GET_STRIKE_AND_REWARDS_LIST_SUCCESS";
export const GET_STRIKE_AND_REWARDS_LIST_FAILURE =
  "GET_STRIKE_AND_REWARDS_LIST_FAILURE";

export const GET_MEETING_DISCCUSSION_DROPDOWN_DATA_REQUEST =
  "GET_MEETING_DISCCUSSION_DROPDOWN_DATA_REQUEST";
export const GET_MEETING_DISCCUSSION_DROPDOWN_DATA_SUCCESS =
  "GET_MEETING_DISCCUSSION_DROPDOWN_DATA_SUCCESS";
export const GET_MEETING_DISCCUSSION_DROPDOWN_DATA_FAILURE =
  "GET_MEETING_DISCCUSSION_DROPDOWN_DATA_FAILURE";

export const CREATE_DISCUSSION_REQUEST = "CREATE_DISCUSSION_REQUEST";
export const CREATE_DISCUSSION_SUCCESS = "CREATE_DISCUSSION_SUCCESS";
export const CREATE_DISCUSSION_FAILURE = "CREATE_DISCUSSION_FAILURE";

export const GET_COMPLETED_MEETING_REQUEST = "GET_COMPLETED_MEETING_REQUEST";
export const GET_COMPLETED_MEETING_SUCCESS = "GET_COMPLETED_MEETING_SUCCESS";
export const GET_COMPLETED_MEETING_FAILURE = "GET_COMPLETED_MEETING_FAILURE";

export const GET_TASK_DROPDOWN_DATA_REQUEST = "GET_TASK_DROPDOWN_DATA_REQUEST";
export const GET_TASK_DROPDOWN_DATA_SUCCESS = "GET_TASK_DROPDOWN_DATA_SUCCESS";
export const GET_TASK_DROPDOWN_DATA_FAILURE = "GET_TASK_DROPDOWN_DATA_FAILURE";

export const CREATE_TASK_REQUEST = "CREATE_TASK_REQUEST";
export const CREATE_TASK_SUCCESS = "CREATE_TASK_SUCCESS";
export const CREATE_TASK_FAILURE = "CREATE_TASK_FAILURE";

export const GET_MEETING_DISCUSSION_POINT_LIST_REQUEST =
  "GET_MEETING_DISCUSSION_POINT_LIST_REQUEST";
export const GET_MEETING_DISCUSSION_POINT_LIST_SUCCESS =
  "GET_MEETING_DISCUSSION_POINT_LIST_SUCCESS";
export const GET_MEETING_DISCUSSION_POINT_LIST_FAILURE =
  "GET_MEETING_DISCUSSION_POINT_LIST_FAILURE";

export const GET_FOLLOWUPS_DROPDOWN_LIST_REQUEST =
  "GET_FOLLOWUPS_DROPDOWN_LIST_REQUEST";
export const GET_FOLLOWUPS_DROPDOWN_LIST_SUCCESS =
  "GET_FOLLOWUPS_DROPDOWN_LIST_SUCCESS";
export const GET_FOLLOWUPS_DROPDOWN_LIST_FAILURE =
  "GET_FOLLOWUPS_DROPDOWN_LIST_FAILURE";

export const GET_FOLLOWUPS_LIST_REQUEST = "GET_FOLLOWUPS_LIST_REQUEST";
export const GET_FOLLOWUPS_LIST_SUCCESS = "GET_FOLLOWUPS_LIST_SUCCESS";
export const GET_FOLLOWUPS_LIST_FAILURE = "GET_FOLLOWUPS_LIST_FAILURE";

export const CREATE_RESPONSE_REQUEST = "CREATE_RESPONSE_REQUEST";
export const CREATE_RESPONSE_SUCCESS = "CREATE_RESPONSE_SUCCESS";
export const CREATE_RESPONSE_FAILURE = "CREATE_RESPONSE_FAILURE";

export const CANCEL_MEETING__REQUEST = "CANCEL_MEETING__REQUEST";
export const CANCEL_MEETING__SUCCESS = "CANCEL_MEETING__SUCCESS";
export const CANCEL_MEETING__FAILURE = "CANCEL_MEETING__FAILURE";

export const GET_TEAM_PIE_CHART__REQUEST = "GET_TEAM_PIE_CHART__REQUEST";
export const GET_TEAM_PIE_CHART__SUCCESS = "GET_TEAM_PIE_CHART__SUCCESS";
export const GET_TEAM_PIE_CHART__FAILURE = "GET_TEAM_PIE_CHART__FAILURE";

export const GET_TEAM_DURATION_PIE_CHART__REQUEST =
  "GET_TEAM_DURATION_PIE_CHART__REQUEST";
export const GET_TEAM_DURATION_PIE_CHART__SUCCESS =
  "GET_TEAM_DURATION_PIE_CHART__SUCCESS";
export const GET_TEAM_DURATION_PIE_CHART__FAILURE =
  "GET_TEAM_DURATION_PIE_CHART__FAILURE";

export const GET_COMPANY_PIE_CHART__REQUEST = "GET_COMPANY_PIE_CHART__REQUEST";
export const GET_COMPANY_PIE_CHART__SUCCESS = "GET_COMPANY_PIE_CHART__SUCCESS";
export const GET_COMPANY_PIE_CHART__FAILURE = "GET_COMPANY_PIE_CHART__FAILURE";

export const GET_COMPANY_DURATION_PIE_CHART__REQUEST =
  "GET_COMPANY_DURATION_PIE_CHART__REQUEST";
export const GET_COMPANY_DURATION_PIE_CHART__SUCCESS =
  "GET_COMPANY_DURATION_PIE_CHART__SUCCESS";
export const GET_COMPANY_DURATION_PIE_CHART__FAILURE =
  "GET_COMPANY_DURATION_PIE_CHART__FAILURE";

export const GET_PROJECT_PIE_CHART__REQUEST = "GET_PROJECT_PIE_CHART__REQUEST";
export const GET_PROJECT_PIE_CHART__SUCCESS = "GET_PROJECT_PIE_CHART__SUCCESS";
export const GET_PROJECT_PIE_CHART__FAILURE = "GET_PROJECT_PIE_CHART__FAILURE";

export const GET_PROJECT_DURATION_PIE_CHART__REQUEST =
  "GET_PROJECT_DURATION_PIE_CHART__REQUEST";
export const GET_PROJECT_DURATION_PIE_CHART__SUCCESS =
  "GET_PROJECT_DURATION_PIE_CHART__SUCCESS";
export const GET_PROJECT_DURATION_PIE_CHART__FAILURE =
  "GET_PROJECT_DURATION_PIE_CHART__FAILURE";

export const MEETING_DATA_BAR_CHART__REQUEST =
  "MEETING_DATA_BAR_CHART__REQUEST";
export const MEETING_DATA_BAR_CHART__SUCCESS =
  "MEETING_DATA_BAR_CHART__SUCCESS";
export const MEETING_DATA_BAR_CHART__FAILURE =
  "MEETING_DATA_BAR_CHART__FAILURE";

export const FOLLOWUP_CALL_BAR_CHART__REQUEST =
  "FOLLOWUP_CALL_BAR_CHART__REQUEST";
export const FOLLOWUP_CALL_BAR_CHART__SUCCESS =
  "FOLLOWUP_CALL_BAR_CHART__SUCCESS";
export const FOLLOWUP_CALL_BAR_CHART__FAILURE =
  "FOLLOWUP_CALL_BAR_CHART__FAILURE";

export const DISCUSSION_POINT_TYPES_BAR_CHART__REQUEST =
  "DISCUSSION_POINT_TYPES_BAR_CHART__REQUEST";
export const DISCUSSION_POINT_TYPES_BAR_CHART__SUCCESS =
  "DISCUSSION_POINT_TYPES_BAR_CHART__SUCCESS";
export const DISCUSSION_POINT_TYPES_BAR_CHART__FAILURE =
  "DISCUSSION_POINT_TYPES_BAR_CHART__FAILURE";

export const RECURRING_FOLLOWUP_BY_STATUS_BAR_CHART__REQUEST =
  "RECURRING_FOLLOWUP_BY_STATUS_BAR_CHART__REQUEST";
export const RECURRING_FOLLOWUP_BY_STATUS_BAR_CHART__SUCCESS =
  "RECURRING_FOLLOWUP_BY_STATUS_BAR_CHART__SUCCESS";
export const RECURRING_FOLLOWUP_BY_STATUS_BAR_CHART__FAILURE =
  "RECURRING_FOLLOWUP_BY_STATUS_BAR_CHART__FAILURE";

export const MEETING_DATA_STATUS_WISE_BAR_CHART__REQUEST =
  "MEETING_DATA_STATUS_WISE_BAR_CHART__REQUEST";
export const MEETING_DATA_STATUS_WISE_BAR_CHART__SUCCESS =
  "MEETING_DATA_STATUS_WISE_BAR_CHART__SUCCESS";
export const MEETING_DATA_STATUS_WISE_BAR_CHART__FAILURE =
  "MEETING_DATA_STATUS_WISE_BAR_CHART__FAILURE";

export const ACTIONABLE_DISCUSSION_POINTS_BAR_CHART__REQUEST =
  "ACTIONABLE_DISCUSSION_POINTS_BAR_CHART__REQUEST";
export const ACTIONABLE_DISCUSSION_POINTS_BAR_CHART__SUCCESS =
  "ACTIONABLE_DISCUSSION_POINTS_BAR_CHART__SUCCESS";
export const ACTIONABLE_DISCUSSION_POINTS_BAR_CHART__FAILURE =
  "ACTIONABLE_DISCUSSION_POINTS_BAR_CHART__FAILURE";

export const QUERY_RESPONSE_MODE_BAR_CHART__REQUEST =
  "QUERY_RESPONSE_MODE_BAR_CHART__REQUEST";
export const QUERY_RESPONSE_MODE_BAR_CHART__SUCCESS =
  "QUERY_RESPONSE_MODE_BAR_CHART__SUCCESS";
export const QUERY_RESPONSE_MODE_BAR_CHART__FAILURE =
  "QUERY_RESPONSE_MODE_BAR_CHART__FAILURE";

export const INCOMING_QUERY_BY_STATUS_BAR_CHART__REQUEST =
  "INCOMING_QUERY_BY_STATUS_BAR_CHART__REQUEST";
export const INCOMING_QUERY_BY_STATUS_BAR_CHART__SUCCESS =
  "INCOMING_QUERY_BY_STATUS_BAR_CHART__SUCCESS";
export const INCOMING_QUERY_BY_STATUS_BAR_CHART__FAILURE =
  "INCOMING_QUERY_BY_STATUS_BAR_CHART__FAILURE";

export const MEETING_DATA_BY_DURATIONS_CHART__REQUEST =
  "MEETING_DATA_BY_DURATIONS_CHART__REQUEST";
export const MEETING_DATA_BY_DURATIONS_CHART__SUCCESS =
  "MEETING_DATA_BY_DURATIONS_CHART__SUCCESS";
export const MEETING_DATA_BY_DURATIONS_CHART__FAILURE =
  "MEETING_DATA_BY_DURATIONS_CHART__FAILURE";

export const STRIKE_AND_REWARD_PROGRESS_BAR__REQUEST =
  "STRIKE_AND_REWARD_PROGRESS_BAR__REQUEST";
export const STRIKE_AND_REWARD_PROGRESS_BAR__SUCCESS =
  "STRIKE_AND_REWARD_PROGRESS_BAR__SUCCESS";
export const STRIKE_AND_REWARD_PROGRESS_BAR__FAILURE =
  "STRIKE_AND_REWARD_PROGRESS_BAR__FAILURE";

export const CONCLUDE_FOLLOWUP__REQUEST = "CONCLUDE_FOLLOWUP__REQUEST";
export const CONCLUDE_FOLLOWUP__SUCCESS = "CONCLUDE_FOLLOWUP__SUCCESS";
export const CONCLUDE_FOLLOWUP__FAILURE = "CONCLUDE_FOLLOWUP__FAILURE";

export const ADD_FOLLOWUP_CALL__REQUEST = "ADD_FOLLOWUP_CALL__REQUEST";
export const ADD_FOLLOWUP_CALL__SUCCESS = "ADD_FOLLOWUP_CALL__SUCCESS";
export const ADD_FOLLOWUP_CALL__FAILURE = "ADD_FOLLOWUP_CALL__FAILURE";

export const EA_DESIGNATION_USER__REQUEST = "EA_DESIGNATION_USER__REQUEST";
export const EA_DESIGNATION_USER__SUCCESS = "EA_DESIGNATION_USER__SUCCESS";
export const EA_DESIGNATION_USER__FAILURE = "EA_DESIGNATION_USER__FAILURE";

export const ADD_FOLLOWUP_REMARK__REQUEST = "ADD_FOLLOWUP_REMARK__REQUEST";
export const ADD_FOLLOWUP_REMARK__SUCCESS = "ADD_FOLLOWUP_REMARK__SUCCESS";
export const ADD_FOLLOWUP_REMARK__FAILURE = "ADD_FOLLOWUP_REMARK__FAILURE";

export const GET_DASHBOARD_METRICS__REQUEST = "GET_DASHBOARD_METRICS__REQUEST";
export const GET_DASHBOARD_METRICS__SUCCESS = "GET_DASHBOARD_METRICS__SUCCESS";
export const GET_DASHBOARD_METRICS__FAILURE = "GET_DASHBOARD_METRICS__FAILURE";

export const GET_CREATE_FOLLOWUPS_DROPDOWN__REQUEST =
  "GET_CREATE_FOLLOWUPS_DROPDOWN__REQUEST";
export const GET_CREATE_FOLLOWUPS_DROPDOWN__SUCCESS =
  "GET_CREATE_FOLLOWUPS_DROPDOWN__SUCCESS";
export const GET_CREATE_FOLLOWUPS_DROPDOWN__FAILURE =
  "GET_CREATE_FOLLOWUPS_DROPDOWN__FAILURE";

export const ADD_CREATE_FOLLOWUP_MEETING__REQUEST =
  "ADD_CREATE_FOLLOWUP_MEETING__REQUEST";
export const ADD_CREATE_FOLLOWUP_MEETING__SUCCESS =
  "ADD_CREATE_FOLLOWUP_MEETING__SUCCESS";
export const ADD_CREATE_FOLLOWUP_MEETING__FAILURE =
  "ADD_CREATE_FOLLOWUP_MEETING__FAILURE";

export const GET_DISCUSSION_DETAIL__REQUEST = "GET_DISCUSSION_DETAIL__REQUEST";
export const GET_DISCUSSION_DETAIL__SUCCESS = "GET_DISCUSSION_DETAIL__SUCCESS";
export const GET_DISCUSSION_DETAIL__FAILURE = "GET_DISCUSSION_DETAIL__FAILURE";

export const GET_TASK_SUMMARY__REQUEST = "GET_TASK_SUMMARY__REQUEST";
export const GET_TASK_SUMMARY__SUCCESS = "GET_TASK_SUMMARY__SUCCESS";
export const GET_TASK_SUMMARY__FAILURE = "GET_TASK_SUMMARY__FAILURE";

export const RESCHEDULE_MEETING__REQUEST = "RESCHEDULE_MEETING__REQUEST";
export const RESCHEDULE_MEETING__SUCCESS = "RESCHEDULE_MEETING__SUCCESS";
export const RESCHEDULE_MEETING__FAILURE = "RESCHEDULE_MEETING__FAILURE";

export const RECURRING_FOLLOWUP_DETAILS__REQUEST =
  "RECURRING_FOLLOWUP_DETAILS__REQUEST";
export const RECURRING_FOLLOWUP_DETAILS__SUCCESS =
  "RECURRING_FOLLOWUP_DETAILS__SUCCESS";
export const RECURRING_FOLLOWUP_DETAILS__FAILURE =
  "RECURRING_FOLLOWUP_DETAILS__FAILURE";

export const MARK_AS_COMPLETE__REQUEST = "MARK_AS_COMPLETE__REQUEST";
export const MARK_AS_COMPLETE__SUCCESS = "MARK_AS_COMPLETE__SUCCESS";
export const MARK_AS_COMPLETE__FAILURE = "MARK_AS_COMPLETE__FAILURE";

export const GET_TASK_FOLLOWUP_DETAILS__REQUEST =
  "GET_TASK_FOLLOWUP_DETAILS__REQUEST";
export const GET_TASK_FOLLOWUP_DETAILS__SUCCESS =
  "GET_TASK_FOLLOWUP_DETAILS__SUCCESS";
export const GET_TASK_FOLLOWUP_DETAILS__FAILURE =
  "GET_TASK_FOLLOWUP_DETAILS__FAILURE";

export const GET_FOLLOWUP_CALL_ID__REQUEST = "GET_FOLLOWUP_CALL_ID__REQUEST";
export const GET_FOLLOWUP_CALL_ID__SUCCESS = "GET_FOLLOWUP_CALL_ID__SUCCESS";
export const GET_FOLLOWUP_CALL_ID__FAILURE = "GET_FOLLOWUP_CALL_ID__FAILURE";

export const GET_ATTENDEE_LIST__REQUEST = "GET_ATTENDEE_LIST__REQUEST";
export const GET_ATTENDEE_LIST__SUCCESS = "GET_ATTENDEE_LIST__SUCCESS";
export const GET_ATTENDEE_LIST__FAILURE = "GET_ATTENDEE_LIST__FAILURE";

export const MARK_ATTENDEE_ABSENT_PRESENT__REQUEST =
  "MARK_ATTENDEE_ABSENT_PRESENT__REQUEST";
export const MARK_ATTENDEE_ABSENT_PRESENT__SUCCESS =
  "MARK_ATTENDEE_ABSENT_PRESENT__SUCCESS";
export const MARK_ATTENDEE_ABSENT_PRESENT__FAILURE =
  "MARK_ATTENDEE_ABSENT_PRESENT__FAILURE";

export const GET_SETTING_DATA__REQUEST = "GET_SETTING_DATA__REQUEST";
export const GET_SETTING_DATA__SUCCESS = "GET_SETTING_DATA__SUCCESS";
export const GET_SETTING_DATA__FAILURE = "GET_SETTING_DATA__FAILURE";

export const GET_REASON_DATA__REQUEST = "GET_REASON_DATA__REQUEST";
export const GET_REASON_DATA__SUCCESS = "GET_REASON_DATA__SUCCESS";
export const GET_REASON_DATA__FAILURE = "GET_REASON_DATA__FAILURE";

export const DELETE_RECURRING_SETUP_DATA__REQUEST =
  "DELETE_RECURRING_SETUP_DATA__REQUEST";
export const DELETE_RECURRING_SETUP_DATA__SUCCESS =
  "DELETE_RECURRING_SETUP_DATA__SUCCESS";
export const DELETE_RECURRING_SETUP_DATA__FAILURE =
  "DELETE_RECURRING_SETUP_DATA__FAILURE";

export const EDIT_UPDATE_MEETING__REQUEST = "EDIT_UPDATE_MEETING__REQUEST";
export const EDIT_UPDATE_MEETING__SUCCESS = "EDIT_UPDATE_MEETING__SUCCESS";
export const EDIT_UPDATE_MEETING__FAILURE = "EDIT_UPDATE_MEETING__FAILURE";

export const GET_CALL_CREATE_DATA__REQUEST = "GET_CALL_CREATE_DATA__REQUEST";
export const GET_CALL_CREATE_DATA__SUCCESS = "GET_CALL_CREATE_DATA__SUCCESS";
export const GET_CALL_CREATE_DATA__FAILURE = "GET_CALL_CREATE_DATA__FAILURE";

export const GET_FOLLOWUPS_CALL_SUMMARY__REQUEST =
  "GET_FOLLOWUPS_CALL_SUMMARY__REQUEST";
export const GET_FOLLOWUPS_CALL_SUMMARY__SUCCESS =
  "GET_FOLLOWUPS_CALL_SUMMARY__SUCCESS";
export const GET_FOLLOWUPS_CALL_SUMMARY__FAILURE =
  "GET_FOLLOWUPS_CALL_SUMMARY__FAILURE";

export const START_FOLLOWUP_CALL__REQUEST = "START_FOLLOWUP_CALL__REQUEST";
export const START_FOLLOWUP_CALL__SUCCESS = "START_FOLLOWUP_CALL__SUCCESS";
export const START_FOLLOWUP_CALL__FAILURE = "START_FOLLOWUP_CALL__FAILURE";

export const FOLLOWUP_CALL_DETAILS__REQUEST = "FOLLOWUP_CALL_DETAILS__REQUEST";
export const FOLLOWUP_CALL_DETAILS__SUCCESS = "FOLLOWUP_CALL_DETAILS__SUCCESS";
export const FOLLOWUP_CALL_DETAILS__FAILURE = "FOLLOWUP_CALL_DETAILS__FAILURE";

export const HOLD_CALL__REQUEST = "HOLD_CALL__REQUEST";
export const HOLD_CALL__SUCCESS = "HOLD_CALL__SUCCESS";
export const HOLD_CALL__FAILURE = "HOLD_CALL__FAILURE";

export const UNHOLD_CALL__REQUEST = "UNHOLD_CALL__REQUEST";
export const UNHOLD_CALL__SUCCESS = "UNHOLD_CALL__SUCCESS";
export const UNHOLD_CALL__FAILURE = "UNHOLD_CALL__FAILURE";

export const END_CALL__REQUEST = "END_CALL__REQUEST";
export const END_CALL__SUCCESS = "END_CALL__SUCCESS";
export const END_CALL__FAILURE = "END_CALL__FAILURE";

export const USER_LIST_FOR_CALL__REQUEST = "USER_LIST_FOR_CALL__REQUEST";
export const USER_LIST_FOR_CALL__SUCCESS = "USER_LIST_FOR_CALL__SUCCESS";
export const USER_LIST_FOR_CALL__FAILURE = "USER_LIST_FOR_CALL__FAILURE";

export const TRANSFER_CALL__REQUEST = "TRANSFER_CALL__REQUEST";
export const TRANSFER_CALL__SUCCESS = "TRANSFER_CALL__SUCCESS";
export const TRANSFER_CALL__FAILURE = "TRANSFER_CALL__FAILURE";

export const CONFERENCE_CALL__REQUEST = "CONFERENCE_CALL__REQUEST";
export const CONFERENCE_CALL__SUCCESS = "CONFERENCE_CALL__SUCCESS";
export const CONFERENCE_CALL__FAILURE = "CONFERENCE_CALL__FAILURE";

export const FOLLOWUP_CALL_DETAILS_LIST_DATA__REQUEST =
  "FOLLOWUP_CALL_DETAILS_LIST_DATA__REQUEST";
export const FOLLOWUP_CALL_DETAILS_LIST_DATA__SUCCESS =
  "FOLLOWUP_CALL_DETAILS_LIST_DATA__SUCCESS";
export const FOLLOWUP_CALL_DETAILS_LIST_DATA__FAILURE =
  "FOLLOWUP_CALL_DETAILS_LIST_DATA__FAILURE";

export const GET_FOLLOWUP_CALL_IDS__REQUEST = "GET_FOLLOWUP_CALL_IDS__REQUEST";
export const GET_FOLLOWUP_CALL_IDS__SUCCESS = "GET_FOLLOWUP_CALL_IDS__SUCCESS";
export const GET_FOLLOWUP_CALL_IDS__FAILURE = "GET_FOLLOWUP_CALL_IDS__FAILURE";
