import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Loader from "./Components/Loader/Loader";
import ProtectedRoutes from "./Components/ProtectedRoutes/ProtectedRoutes";
import NoRoutes from "./Components/Loader/NoRoutes";
import RoleRoute from "./Middleware/RoleRoutes";

const Dashboard = lazy(() => import("./Screens/Dashboard/Dashboard"));
const Login = lazy(() => import("./Screens/Login/Login"));
const Meetings = lazy(() => import("./Screens/Meetings/Meetings"));
const IncomingQueries = lazy(() =>
  import("./Screens/IncomingQueries/IncomingQueries")
);
const MeetingPoints = lazy(() =>
  import("./Screens/MeetingPoints/MeetingPoints")
);
const FollowUps = lazy(() => import("./Screens/FollowUps/FollowUps"));
const StrikeRewards = lazy(() =>
  import("./Screens/StrikeRewards/StrikeRewards")
);
const Settings = lazy(() => import("./Screens/Settings/Settings"));
const UpcomingMeetings = lazy(() =>
  import("./Screens/UpcomingMeetings/UpcomingMeetings")
);
const CompleteMeetings = lazy(() =>
  import("./Screens/CompleteMeetings/CompleteMeetings")
);
const RecurringSettings = lazy(() =>
  import("./Screens/Recurring/RecurringSettings")
);
const RecurringFollowups = lazy(() =>
  import("./Screens/Recurring/RecurringFollowups")
);
const CreateMeet = lazy(() => import("./Components/CreateMeet/CreateMeet"));
const AddQueries = lazy(() => import("./Screens/AddQueries/AddQueries"));
const FollowupsCallSummary = lazy(() =>
  import("./Screens/FollowupsCallSummary/FollowupsCallSummary")
);
const Audio = lazy(() => import("./Audio"));
const FollowupCalls = lazy(() =>
  import("./Screens/FollowupCalls/FollowupCalls")
);

const App = () => {
  // console.log = function () {};
  // console.warn = function () {};
  // console.error = function () {};
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route element={<Login />} path="/login" />
          <Route element={<ProtectedRoutes />}>
            <Route element={<RoleRoute element={<Dashboard />} />} path="/" />
            <Route element={<Meetings />} path="/meetings" />
            <Route element={<IncomingQueries />} path="/incoming-queries" />
            <Route element={<MeetingPoints />} path="/meeting-points/:id?" />
            <Route element={<FollowUps />} path="/followups/:id?" />
            <Route
              element={<RoleRoute element={<StrikeRewards />} />}
              path="/strike-rewards"
            />
            <Route
              element={<RoleRoute element={<Settings />} />}
              path="/settings"
            />
            <Route element={<UpcomingMeetings />} path="/upcoming-meetings" />
            <Route element={<CompleteMeetings />} path="/complete-meetings" />
            <Route element={<RecurringSettings />} path="/recurring-setup" />
            <Route
              element={<RecurringFollowups />}
              path="/recurring-followups"
            />
            <Route
              element={<CreateMeet />}
              path="/create-meeting/:id?/:followupIds?/:points?"
            />
            <Route element={<AddQueries />} path="/add-query/:id?" />
            <Route element={<Audio />} path="/audio" />
            <Route element={<FollowupCalls />} path="/followup-calls/:id" />
            <Route
              element={<FollowupsCallSummary />}
              path="/recurring-followups-call-summary"
            />
          </Route>
          <Route
            element={<NoRoutes title="Oops No Route Found..." />}
            path="*"
          />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
